<template>
  <div class="s-layout" :style="colorObject">
    <b-modal id="modal-tip" scrollable centered :hide-header="tip && !tip.title" :hide-footer="tip && !tip.button_text" class="tips">
      <template #modal-header>
        <div class="font-weight-bold h6 mb-0">
          {{ tip.title }}
        </div>
      </template>

      <template #default>
        <button class="tips__close" @click="close()">
          <img src="./images/close.svg" />
        </button>
        <div class="p-2" :style="colorObject">
          <div v-if="tip.content" v-html="tip.content"></div>
        </div>
      </template>
      <template #modal-footer="{ close }">
        <div :style="colorObject" class="flex-fill">
          <SharedButton class="w-100" @click="close()">{{
            tip.button_text
          }}</SharedButton>
        </div>
      </template>
    </b-modal>

    <div class="s-container">
      <Header
        :meta="meta"
        :showMemberCenter="false"
        :pageTitle="getModuleConfig('page_title')"
        :pageImage="getModuleConfig('page_image')"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";
import Header from "@/components/Page/Liff/Shared/Header";
import registerMixin from "@/mixins/liff/register";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  components: {
    Header,
    SharedButton,
  },
  mixins: [themeColorFn({ themeConfigPage: 'register' }), registerMixin],
  data() {
    return {
      tip: null,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-tip") {
        var uri = window.location.toString();

        if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("#"));

          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    });

    console.log(this.getModuleConfig("modal_tips"));

    if (this.getModuleConfig("modal_tips")) {
      window.addEventListener("hashchange", () => {
        if (window.location.hash) {
          this.tip = this.getModuleConfig("modal_tips").find(
            (x) => x.id == window.location.hash.substring(1)
          );
          this.$bvModal.show("modal-tip");
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;
    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }
  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}

.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 14px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    & + label::after {
      left: -1.5rem;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before,
    &:active + label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: var(--s-gray-light);
    }
  }
}

.tips {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }
}

.modal-content {
  border-radius: 10px;
  font-size: 15px;

  .modal-header,
  .modal-footer {
    border-top: none;
    border-bottom: none;
  }
}

.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
